// Imports
// ------------
import React, { useEffect, useRef } from 'react';
import { loadingState, menuState } from '@states';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';

// GLApp
// Styles
// ------------
import { Jacket } from './styles';
import GLApp from './webgl';

// Component
// ------------
const VideoBackground = ({ centered }) => {

	let webglRef = useRef();
	let canvasRef = useRef()
	// NOTE • States

	// NOTE • OnMount: Check if Loader has finished
	useEffect(() => {
		let readyCallback = action(() => {
			loadingState.isFinished = true;
		})

		webglRef.current = new GLApp(canvasRef.current, centered, readyCallback)
	}, []);

	useEffect(() => {
		if (webglRef.current == null) return;
		if (menuState.isOpen) {
			webglRef.current.hide()
		} else {

			webglRef.current.show()
		}
	}, [menuState.isOpen])

	return (
		<Jacket isReady={true} isMenuOpen={menuState.isOpen}>
			<canvas ref={canvasRef} />
		</Jacket>
	);
}

export default observer(VideoBackground);