// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    ${props.theme.vh}
    position: relative;
    z-index: 1;
    width: 100%;
    background: transparent;
    overflow: hidden;

    // opacity: ${props.isReady ? 1 : 0};
    // transition: opacity 1s ${props.theme.easing.ease} 0.5s;
    // transition-delay: 0.5s;

	canvas {
		
		width: 100%;
		height: 100%;
	}
`);
