
import * as THREE from "three";
import { Structure } from "./Structure";
// import { evt } from "./plugins";

// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
// import { getFibonacciSpherePoints, vector3toLonLat } from "./sphereUtils";
import { InstancedBufferGeometry, ShaderMaterial } from "three";
import gsap from "gsap";
import { lerp } from "three/src/math/MathUtils";
import points from "./points";
import { createGlowMesh } from "./glowMesh";

let TIMEOUT = 2000;
let VIEW_WIDTH = 0.7;
let MOBILE_VIEW_WIDTH = 1.2;
let WHEEL_ROTATION_SPEED = 0.02;
let ROTATION_SPEED = 0.002
let ROTATION_LERP = 0.005
let MOUSE_ROTATION_LERP = 0.05


class GLApp extends Structure {
	constructor(canvas, centered = false, onReady = null) {
		super(canvas);

		this.onReady = onReady
		this.onResize = this.onResize.bind(this);
		this.onWheel = this.onWheel.bind(this);

		this.centered = centered;


		this.y = 0
		this.rotation = 0;
		this.rotationTarget = 0;

		this.shouldAnimateIn = false;
		this.animatedIn = false;

		this.load().then(this.init.bind(this))
	}
	loadAssets() {
	}
	onResize() {
		super.onResize();

		gsap.killTweensOf(this.positionParent.scale)
		gsap.killTweensOf(this.positionParent.position)

		let width = window.innerWidth

		this.setSize()
		let isDesktop = width >= 1024;
		this.positionParent.scale.setScalar(1)
		if (isDesktop) {
			this.positionParent.position.x = -this.getViewSizeAtDepth().width / 2 + (100 - 30);
			this.positionParent.position.y = 0;
		} else {
			this.positionParent.position.y = this.getViewSizeAtDepth().height / 2 - (100 - 80);
			this.positionParent.position.x = 0;

		}

		if (this.centered) {
			this.positionParent.position.y = 0;
			this.positionParent.position.x = 0;

		}

	}
	show() {
		if (!this.loaded) return;
		gsap.killTweensOf(this.scaleParent.scale)
		gsap.to(this.scaleParent.scale, { x: 1, y: 1, z: 1, duration: 1, ease: 'power2.inOut' })

		// gsap.fromTo(this.scaleParent.scale, {x: 0}, { x:1, duration: 1, ease: 'power2.inOut' })

	}
	hide() {

		if (!this.loaded) return;

		gsap.killTweensOf(this.scaleParent.scale)

		gsap.to(this.scaleParent.scale, { x: 0, y: 0, z: 0, duration: 1, ease: 'power2.inOut' })

		// gsap.fromTo(this.scaleParent.scale, {x: 0}, { x:1, duration: 1, ease: 'power2.inOut' })

	}
	animate() {
		if (!this.loaded) {
			this.shouldAnimateIn = true;
			return;
		}

		if (this.centered) {
			this.positionParent.scale.setScalar(1)
			this.positionParent.position.setScalar(0)
			return;
		}
		this.animatedIn = true;

		gsap.fromTo(this.positionParent.scale, { x: 0, y: 0, z: 0 }, { x: 1, y: 1, z: 1, duration: 1, ease: 'power2.inOut' })

		let width = window.innerWidth

		let isDesktop = width >= 1024;
		if (isDesktop) {
			gsap.fromTo(this.positionParent.position, { x: 0 }, { x: -this.getViewSizeAtDepth().width / 2 + (100 - 30), duration: 1, ease: 'power2.inOut' })

		} else {
			gsap.fromTo(this.positionParent.position, { y: 0 }, { y: this.getViewSizeAtDepth().height / 2 - (100 - 80), duration: 1, ease: 'power2.inOut' })

		}

	}
	load() {

		return new Promise((res) => {

			let manager = new THREE.LoadingManager(() => {
				this.loaded = true;
				res()
			});
			let loader = new THREE.TextureLoader(manager)

			loader.load('/globe.png', (tex) => {
				this.assets['globe'] = tex;
			})

			loader.load('/perlin.jpg', (perlin) => {
				this.assets['perlin'] = perlin;
			})
		})

	}
	setSize() {

		let viewSize = this.getViewSizeAtDepth();
		let isDesktop = window.innerWidth >= 1024;
		let percentage = isDesktop ? VIEW_WIDTH : MOBILE_VIEW_WIDTH;
		let targetWidth = (viewSize.width * percentage) / 200;

		let target = targetWidth
		this.rotationParent.scale.setScalar(target)
	}
	init() {
		let cubeGeo = new THREE.BoxBufferGeometry();
		let cubeMat = new THREE.MeshNormalMaterial();
		let cubeMesh = new THREE.Mesh(cubeGeo, cubeMat);

		let rotationParent = new THREE.Object3D()
		this.rotationParent = rotationParent
		this.setSize()

		let positionParent = new THREE.Object3D();
		this.positionParent = positionParent
		this.scene.add(positionParent)

		this.scaleParent = new THREE.Object3D()
		this.scaleParent.add(rotationParent)

		positionParent.add(this.scaleParent)
		positionParent.scale.setScalar(0)


		let loader = new THREE.TextureLoader()
		let tex = this.assets.globe
		let perlinTex = this.assets.perlin


		let color = new THREE.Color('#282021')
		let colorLight = new THREE.Color('#9d5964')



		let material = new THREE.ShaderMaterial({
			transparent: true,
			uniforms: {
				uMap: { value: tex },
				tPerlin: { value: perlinTex },
				uColor: { value: color },
				uColorLight: { value: colorLight }
			},
			side: THREE.DoubleSide,
			vertexShader: `
			varying vec2 vUv;
			varying vec3 vPos;
			varying vec3 vNormal;
			void main() {
				vec3 transformed = position;
				vUv = uv;
				gl_Position =  projectionMatrix * modelViewMatrix * vec4(transformed, 1.);
				vPos = (modelViewMatrix  * vec4(transformed, 1.)).xyz;
				vNormal = normalMatrix * normal;
			}
		`,
			fragmentShader: `
			precision highp float;
			varying vec2 vUv;
			uniform sampler2D uMap;
			uniform vec3 uColor;
			uniform vec3 uColorLight;
			varying vec3 vPos;
			varying vec3 vNormal;
			
			highp float random(vec2 co)
			{
				highp float a = 12.9898;
				highp float b = 78.233;
				highp float c = 43758.5453;
				highp float dt= dot(co.xy ,vec2(a,b));
				highp float sn= mod(dt,3.14);
				return fract(sin(sn) * c);
			}
			vec2 hash22(vec2 p) {
				vec3 p3 = fract(vec3(p.xyx) * vec3(.1031, .1030, .0973));
				p3 += dot(p3, p3.yzx+33.33);
				return fract((p3.xx+p3.yz)*p3.zy);

			}
			uniform sampler2D tPerlin;
			void main() {
				vec2 uv= vUv;
				vec3 color = vec3(0.);
				color = vec3(uv.x);


				float opacity = 0.5;
				
				float globeOpacity = 0.5;

				float globe = (texture2D(uMap, vUv).r) * globeOpacity;
				float noise = texture2D(tPerlin, vUv).r;

				// vec3 pos = normalize(vPos);

				float thing = dot(normalize(vec3(1.,0.,0.)), vNormal);
				thing = smoothstep(0.3, 0.9, abs(thing));
				// thing = dot(normalize(vec3(1.,0.,0.)), pos);
				// thing = smoothstep(0.1, 0.25, abs(thing));
				thing = thing * thing;

				// Points
				float gridSize = 800.;
				vec2 grid = uv * gridSize * vec2(1.,0.5);

				vec2 gridFrac = fract(grid);
				vec2 gridId = floor(grid);
				vec2 offset = hash22(gridId) * 0.4 - 0.2;
				float point = step( length(gridFrac - 0.5 + offset), 0.2);
				point *= random(gridId) * 0.5 + 0.5;
				// point *= 0.5;

				float limit = smoothstep( 0.,0.2, abs(uv.y- 0.5));
				limit = (1.-(1.-limit) * (1.-limit) * (1.-limit));

				float baseLimit = 0.2;
				if(random(gridId) > baseLimit - limit * baseLimit) {
					point = 0.;
				}




				opacity *= (globe + point);

				// color = vec3(limit);
				float rimLight = dot(normalize(vec3(0.,0.,-1.)), vNormal);
				rimLight = smoothstep(-0.3, 0.3, rimLight) * smoothstep(0.3, - 0.3, rimLight);
				rimLight = rimLight * rimLight ;

				float horizontalBand =  dot(normalize(vec3(0.,1.,0.)), vNormal);
				horizontalBand = smoothstep( 0.8,0.1, abs(horizontalBand));

				float dotSide = clamp(rimLight * horizontalBand, 0., 1.);
				color = mix(uColor, uColorLight,dotSide * 12.+ thing * 1.3 +  point * 4. + smoothstep(0.4,0.7, noise) * 0.7 );
				
				// color = vec3(thing);
				// opacity = 1.;
				gl_FragColor = vec4(color, opacity + dotSide);
			}
		`
		})




		let sphereGeo = new THREE.SphereBufferGeometry(100, 50, 50)

		let globeMesh = new THREE.Mesh(sphereGeo, material);
		globeMesh.rotation.y = -Math.PI / 2.
		rotationParent.add(globeMesh)
		let arr = points




		let planeGeo = new THREE.PlaneBufferGeometry(1, 1, 1, 1);
		let geometry = new InstancedBufferGeometry().copy(planeGeo)
		geometry.instanceCount = arr.length / 3.;
		geometry.setAttribute('aPos', new THREE.InstancedBufferAttribute(new Float32Array(arr), 4, false))

		// geometry = planeGeo;
		let cLight = new THREE.Color('#e9dce0');
		// let cDark = 
		let dotsMaterial = new ShaderMaterial({
			uniforms: {
				uColor: { value: colorLight },
				uColor2: { value: cLight },
			},
			vertexShader: `
			varying vec2 vUv;
			attribute vec4 aPos;
			varying float vSize;
			mat3 calcLookAtMatrix(vec3 origin, vec3 target, float roll) {
				vec3 rr = vec3(sin(roll), cos(roll), 0.0);
				vec3 ww = normalize(target - origin);
				vec3 uu = normalize(cross(ww, rr));
				vec3 vv = normalize(cross(uu, ww));
			  
				return mat3(uu, vv, ww);
			  }

			  
			varying vec3 vPos;
			void main() {
				vec3 transformed = position;
				transformed *= aPos.a;
				transformed = calcLookAtMatrix(aPos.xyz , vec3(0.), 0.) * transformed;
				transformed += aPos.xyz;

				vSize = aPos.a + aPos.x;

				vUv = uv;
				gl_Position =  projectionMatrix * modelViewMatrix * vec4(transformed, 1.);
				vPos = (modelViewMatrix  * vec4(transformed, 1.)).xyz - (modelViewMatrix * vec4(vec3(0.), 1.)).xyz;

			}
		`,
			fragmentShader: `
			precision highp float;
			varying vec2 vUv;
			uniform vec3 uColor;
			uniform vec3 uColor2;
			varying float vSize;

			varying vec3 vPos;
			
		highp float random(vec2 co)
		{
			highp float a = 12.9898;
			highp float b = 78.233;
			highp float c = 43758.5453;
			highp float dt= dot(co.xy ,vec2(a,b));
			highp float sn= mod(dt,3.14);
			return fract(sin(sn) * c);
		}
			void main() {
				vec2 uv= vUv;
				vec3 color = vec3(0.);
				color = vec3(uv.x);
				float len = length(uv - 0.5);
				color = vec3(1., 0.5, 0.5) ;


				vec3 pos = normalize(vPos);

				float thing = dot(normalize(vec3(1.,0.,0.)), pos);
				thing = smoothstep(0.1, 0.25, abs(thing));

				color = mix(uColor, uColor2,thing + smoothstep(0.3, 1., random(vec2(vSize))) );
				float opacity = 1.;
				opacity *= smoothstep(0.5,0.49, len);
				// color = vec3(thing);
				// opacity = 1.;
				// color = vec3(dot(normalize(vec3(1.,0.,0.)), pos));
				// color = vec3();
				color *= vec3(0.5 + 0.5* (vPos.z/200. + 0.5));
				gl_FragColor = vec4(color,opacity);
			}
		`,
			transparent: true,
			side: THREE.DoubleSide,
			depthTest: false,
		})

		let dotsInstancedMesh = new THREE.Mesh(geometry, dotsMaterial);
		dotsInstancedMesh.renderOrder = 1

		rotationParent.add(dotsInstancedMesh);

		let glowMesh = createGlowMesh(sphereGeo, {

			backside: true,
			color: '#9d5964',
			size: 100 * 0.1,
			power: 2, // dispersion
			coefficient: 0.1
		})

		rotationParent.add(glowMesh)
		this.scene.add(new THREE.AmbientLight(0xbbbbbb));
		this.scene.add(new THREE.DirectionalLight(0xffffff, 0.6))

		this.cube = cubeMesh;
		// this.scene.add(cubeMesh);
		this.loadAssets();
		this.addEvents();


		if (this.shouldAnimateIn) this.animate()


		if(this.onReady) this.onReady();
	}
	onMouseMove(ev){
		this.y = (ev.clientY / this.vp.height) * 2. - 1.;

	}
	onWheel() {
		this.rotationTarget += WHEEL_ROTATION_SPEED;
	}
	addEvents() {
		// evt.on("resize", this.onResize);
		// evt.on("tick", this.tick);

		window.addEventListener('resize', this.onResize);
		window.addEventListener('wheel', this.onWheel)
		window.addEventListener('mousemove', this.onMouseMove.bind(this));

		setTimeout(this.animate.bind(this), TIMEOUT);
		this.tick();
	}
	update() {
		// this.cube.rotation.x += 0.01;

		this.rotationTarget += ROTATION_SPEED;

		// this.positionParent.rotation.x = this.y * Math.PI * 0.2
		this.positionParent.rotation.x = lerp(this.positionParent.rotation.x, this.y * Math.PI * 0.07, MOUSE_ROTATION_LERP); 


		this.rotationParent.rotation.y = lerp(this.rotationParent.rotation.y, this.rotationTarget, ROTATION_LERP);
		// this.rotation = 0
	}
	dispose() {
		// 
	}
}

export default GLApp;